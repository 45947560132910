<template>
    <div class="filter-toggle">
        <div class="filter-toggle__heading">
            <p>{{filterName}}:</p>
        </div>
        <ul class="">
            <li v-for="item in filter" :key="item.id" v-ripple @click="addFilter(item)" v-bind:class="{active:isActive === item.id}">{{item.name}}</li>
        </ul> 
    </div>  
</template>
<script>
export default {
    props:['filterName','filter'],
    data() {
        return {
            isActive:null,
        }
    },
    methods: {
        addFilter(item){
            if(this.isActive === item.id){
                this.isActive = null
            } else {
                this.isActive = item.id;
            }
            this.$emit('addFilter', this.isActive)
        }
    }
}
</script>